import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http/';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  base_url: string;
  changedReq: any;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // throw new Error("Method not implemented.");

    this.base_url = "https://aw.chameleoni.com/";  //live api
    // this.base_url = "http://localhost:5001/";   //local api
    // this.base_url = "https://axcis.chameleoni.com/";  //dev api

    this.changedReq = req.clone({
      url: this.base_url + req.url,
      //headers: reqheaders
    });
    return next.handle(this.changedReq);
  }
  constructor() { }
}
